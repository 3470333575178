import createClient from "openapi-fetch";
import type { components, paths } from "./openapi";
import { useSocket } from "./socketio";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

if (!API_BASE_URL) {
  throw new Error("Missing API base url")
}

const fetcher = createClient<paths>({
  baseUrl: API_BASE_URL,
});


const serializeError = (
  error: (
    components["schemas"]["GenericErrorResponse"] |
    components["schemas"]["HTTPValidationError"]
  ),
  status: number,
): Error => {
  let err: Error;
  if (status === 422) {
    err = new Error("Error: Validation error")
  } else {
    err = new Error(`Error: ${(error as components["schemas"]["GenericErrorResponse"]).detail.message}`)
  }

  err.cause = {
    error,
    status
  }

  return err
}

export { fetcher, serializeError, useSocket };
