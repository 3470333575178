import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL

let socket: Socket | null = null;
let currentToken: string | null = null;

const getSocketInstance = async (getToken: () => Promise<string | null>): Promise<Socket> => {
  const token = await getToken();

  if (!socket || token !== currentToken) {
    if (socket) {
      socket.disconnect();
    }

    currentToken = token;

    socket = io(API_BASE_URL, {
      auth: {
        token: `Bearer ${token}`,
      },
    });

    socket.on("connect_error", (err: Error) => {
      console.error("Socket connection error:", err.message);
    });
  }

  return socket;
};

export const useSocket = (getToken: () => Promise<string | null>) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    let isActive = true;

    const initializeSocket = async () => {
      const newSocket = await getSocketInstance(getToken);
      if (isActive) {
        setSocket(newSocket);
      }
    };

    initializeSocket();

    return () => {
      isActive = false;
    };
  }, [getToken]);

  return socket;
};
