import { useAuth } from '@clerk/clerk-react';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/form";
import { Sparkles } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@repo/ui/components/ui/button";
import { Input } from "@repo/ui/components/ui/input";
import { CircleCheck, LoaderCircle } from 'lucide-react';
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { io } from "socket.io-client";
import { useSocket } from "../services/api";


const socket = io(import.meta.env.VITE_API_BASE_URL);


const formSchema = z.object({
  website: z.string().url(),
})

type FormType = z.infer<typeof formSchema>;

interface FormCardProps {}

const FormCard: React.FC<FormCardProps> = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  })

  return (
    <Card className="w-96">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(({ website }) => {
            if (socket) {
              socket.emit("workspace:create", { website })
            }
          })}
          className="flex flex-col gap-6"
        >
          <CardHeader className="text-center">
            <CardTitle>Start Your Journey</CardTitle>
            <CardDescription>Enter your website, and we'll fill the rest.</CardDescription>
          </CardHeader>
          <CardContent className="text-center">
            <FormField
              control={form.control}
              name="website"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Website</FormLabel>
                  <FormControl>
                    <Input placeholder="https://myawesomewebsite.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>
          <CardFooter className="flex items-center justify-center">
            <Button
              type="submit"
              variant="accent"
              className="flex gap-2"
            >
              <Sparkles className="size-5 animate-pulse" />
              <span className="animate-pulse">Make It Happen</span>
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  )
}


interface Step {
  id: string;
  message: string;
  isDone: boolean;
}


interface StepStartedPayload {
  id: string;
  message: string;
  timestamp: number;
}
interface StepFinishedPayload {
  id: string;
  timestamp: number;
}

interface TaskStartedPayload {}
interface TaskFinishedPayload {
  message: string;
  workspace_id: number
}


interface WorkspacesCreateNewProps {}

const WorkspacesCreate: React.FC<WorkspacesCreateNewProps> = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [newWorkspaceId, setNewWorkspaceId] = useState<number | null>(null);
  const [steps, setSteps] = useState<Step[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      socket.on("task:started", (_: TaskStartedPayload) => {
        setIsSubmitted(true)
      })

      socket.on("task:finished", (data: TaskFinishedPayload) => {
        toast.success(data.message);
        window.setTimeout(() => {
          setNewWorkspaceId(data.workspace_id)
        }, [1000])
      })

      socket.on("step:started", (data: StepStartedPayload) => {
        setSteps(prevSteps => [
          ...prevSteps,
          { id: data.id, message: data.message, isDone: false }
        ])
      })

      socket.on("step:finished", (data: StepFinishedPayload) => {
        setSteps(prevSteps =>
          prevSteps.map(step =>
            step.id === data.id ? { ...step, isDone: true } : step
          )
        );
      })
    }
  }, [socket])

  useEffect(() => {
    if (newWorkspaceId) {
      return navigate(`/workspaces/edit/${newWorkspaceId}`);
    }
  }, [newWorkspaceId])

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      {
        isSubmitted ? (
          <Card className="bg-transparent border-0 shadow-none">
            <CardContent className="p-0 space-y-2">
              {
                steps.map(s => (
                  <div key={`step-${s.id}`} className="flex w-full justify-between items-center mr-4 gap-2">
                    <span className="w-full text-left text-xl">{s.message}</span>
                    {s.isDone ? (
                      <div>
                        <CircleCheck className="text-success-foreground" size="24" />
                      </div>
                    ) : (
                      <div className="animate-spin">
                        <LoaderCircle size="22" />
                      </div>
                    )}
                  </div>
                ))
              }
            </CardContent>
          </Card>
        ) : (
          <FormCard />
        )
      }
    </div>
  )
}

WorkspacesCreate.displayName = "WorkspacesCreate";

export { WorkspacesCreate };
